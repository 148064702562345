import React from 'react';

const Privacy = () => {
    return(
        <>
            <div className="contact section-title mt-5">
                <div className="container text-white">
                    <div className="row align-items-center">
                        <div className="col-md-7 mx-auto">
                            <div className="contact-title mb-5 mt-5">
                                <h1 className="title-font title-font-size">
                                Terms
                                </h1>

                            </div>
                        </div>

                        <div className="col-lg-12 mx-auto text-white">
                            <p className="mt-4 mb-4 title-font-2">
                                Terms & Conditions Pixel Artisan Agency
                            </p>


                            </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Privacy;
