import React from 'react';
import cards from '../img/card_logos.png';
import {Link} from "react-router-dom";

const Footer = () => {
    return (
        <footer>
            <div className="container text-white">
                <div className="row align-items-center">

                    <div className="col-sm-12 mb-4">
                        <img src={cards} alt="Payment cards"/>
                    </div>
                    <div className="col-sm-12 footer-copyright text-center py-3">
                        <ul className="list-inline">
                            <li className="list-inline-item">
                                <Link to="/terms" className="nav-link-footer color-white">Terms & Conditions</Link>
                            </li>
                            <li className="list-inline-item">
                                <Link to="/contact" className="nav-link-footer color-white">Contact</Link>
                            </li>
                            <li className="list-inline-item">
                                <Link to="/privacy" className="nav-link-footer color-white">Privacy Policy</Link>
                            </li>
                        </ul>

                    </div>
                    <div className="col-sm-12">
                        <p className="mt-4 mb-4 title-font-2">©2021 Pixel Artisan Agency. All rights reserved.</p>
                        {/*  <p>SC PIXEL ARTISAN AGENCY SRL-D CIF 35850659 J22/752/2016 Str. Luminii 11 Valea Lupului. Iasi, Romania</p>*/}
                    </div>


                </div>
            </div>
        </footer>
    );
}

export default Footer;
