import { works } from '../data/works';
import { team } from '../data/team';
import { products } from '../data/products';
const initialState = {
    works: works,
    products: products,
    team: team
};

const Reducer = (state = initialState) => {
    return state;
};

export default Reducer;
