import Product1 from '../img/products/estate.jpg';
import Product2 from '../img/products/website.jpg';
import Product3 from '../img/portfolio/p3.jpg';


export const products = [
    {
        "id": 1,
        "src": [Product1],
        "desc": "Ready for production CRM + Website + CMS. Technologies used PHP, Laravel. Design full responsive and fully customizable available in 3 templates. Download link with all required files and setuo documentation is included. For demo please contact us.",
        "title": "PIXEL Estate",
        "price": "149",
        "tehnology": "PHP, Laravel, Javascript",
        "delivery": "Download link",
        "support": "30 days",
        "category": "CRM"
    },
    {
        "id": 2,
        "src": [Product2],
        "desc": "Ready for production Website + CMS. Technologies used PHP, Codeignoter. Design full responsive and fully customizable available in 5 templates. Download link with all required files and setuo documentation is included. For demo please contact us.",
        "title": "PIXEL Website",
        "tehnology": "PHP, Codeigniter, Javascript",
        "delivery": "Download link",
        "support": "30 days",
        "price": "79",
        "category": "Web"
    }
]
