import React from 'react';
import Products from '../components/Products';

const Shop = () => {
    return(
        <>
            <Products/>
        </>
    );
}

export default Shop;
