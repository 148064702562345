export const team = [
    {
        "id": 0,
        "image": "https://images.unsplash.com/photo-1542156822-6924d1a71ace?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80",
        "name": "Desmond Eagle",
        "position": "Co-Founder / President"
    },{
        "id": 1,
        "image": "https://images.unsplash.com/photo-1557862921-37829c790f19?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1351&q=80",
        "name": "Will Barrow",
        "position": "Client Strategist"
    },{
        "id": 2,
        "image": "https://images.unsplash.com/photo-1559548331-f9cb98001426?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80",
        "name": "Jarvis Pepperspray",
        "position": "Paid Media Specialist"
    },{
        "id": 3,
        "image": "https://images.unsplash.com/photo-1553798194-cc0213ae7f99?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1351&q=80",
        "name": "Russell Sprout",
        "position": "Client Strategist"
    },{
        "id": 4,
        "image": "https://images.unsplash.com/photo-1496345875659-11f7dd282d1d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80",
        "name": "Gibson Montgomery",
        "position": "Content Specialist"
    },{
        "id": 5,
        "image": "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80",
        "name": "Bartholomew Shoe",
        "position": "Associate Director of Content Strategy and Social Impact"
    },{
        "id": 6,
        "image": "https://images.unsplash.com/photo-1489980557514-251d61e3eeb6?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80",
        "name": "Eric Widget",
        "position": "Client Strategist"
    },{
        "id": 7,
        "image": "https://images.unsplash.com/photo-1511546395756-590dffdcdbd1?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80",
        "name": "Weir Doe",
        "position": "Co-Founder / Vice President"
    },{
        "id": 8,
        "image": "https://images.unsplash.com/photo-1482264851290-446b18e3ee9f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80",
        "name": "Hugh Saturation",
        "position": "Designer"
    }
]